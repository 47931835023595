import { func, object, string } from "prop-types";
import { Button } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { WarningOutlined } from "@ant-design/icons";
import styles from "./AddNewCampaign.module.css";
import CampaignDetails from "./CampaignDetails";
import { useCreateCampaignSteps } from "../../../api/campaignSteps";
import { useCreateCampaign } from "../../../api/campaigns";
import { camelCaseToSentenceCase } from "../../../utils/stringUtils";
import { toCalendarDate } from "../../../utils/dates";

const generateStepDetails = ({
  campaignState,
  allData,
  updateStep,
  campaignData,
  stepType,
  stepNumber,
}) => {
  const stepName = camelCaseToSentenceCase(stepType);
  if (campaignState[stepType]?.campaignStepId) {
    const { emailTemplates } = allData;
    const step = allData.campaignSteps[campaignState[stepType].campaignStepId];
    campaignData[stepName] = {
      [`${stepName} Name`]: step.name,
      "Max Reminders": step.maxReminders,
      "Hours For Reminders": step.hoursForReminder,
      "Email Template Flow": step.emailTemplateFlow.map(
        templateId => emailTemplates[templateId].name
      ),
      edit: () => updateStep(stepNumber),
    };
    if (stepType === "instagramStep") {
      campaignData[stepName]["Instagram Tags"] = step.metadata?.regex;
    }
  } else if (campaignState.orderStep) {
    const step = campaignState[stepType];
    campaignData[stepName] = {
      [`${stepName} Name`]: step.name,
      "Max Reminders": step.maxReminders,
      "Hours For Reminders": step.hoursForReminder,
      "Email Template Flow": step.emailTemplateFlow.map(
        template => template.name
      ),
      edit: () => updateStep(stepNumber),
    };
    if (stepType === "instagramStep") {
      campaignData[stepName]["Instagram Tags"] = step.instaTags;
    }
  }
};

const generateCampaignDetails = (campaignState, allData, updateStep) => {
  const campaignData = {};
  let currentStep = -1;

  if (campaignState.product?.productId) {
    const step = ++currentStep;
    const product = allData.products[campaignState.product.productId];
    campaignData.Product = {
      Name: product.name,
      Price: `$${product.defaultPrice}`,
      Code: product.code,
      Description: product.description,
      "Short Description": product.shortDescription,
      Image: product.thumbnailUrl,
      edit: () => updateStep(step),
    };
  } else if (campaignState.product) {
    const step = ++currentStep;
    const { product } = campaignState;
    const { productPrices } = product;
    campaignData.Product = {
      Name: product.name,
      Platform: allData.productPlatforms[product.productPlatformId].name,
      Category:
        product.category &&
        product.category[0] &&
        `${allData.categories[product.category[0]].name}/${
          allData.categories[product.category[0]].children[product.category[1]]
            .name
        }`,
      "Product Price": `$${productPrices.productPrice}`,
      "Service Fee": `$${productPrices.serviceFee}`,
      "Service Fee Description": `$${productPrices.serviceFeeDescription}`,
      Code: product.code,
      Description: product.description,
      "Short Description": product.shortDescription,
      Image: product.picture?.thumbnailUrl,
      edit: () => updateStep(step),
    };
  }

  if (campaignState.details) {
    const step = ++currentStep;
    const { details } = campaignState;
    campaignData["Campaign Details"] = {
      "Campaign Name": details.campaignName,
      Target: details.target,
      Link: details.link,
      "Start Date": details.startDate.format("MMM Do YYYY"),
      "Deadline Days": details.deadlineDays,
      edit: () => updateStep(step),
    };
  }

  if (campaignState.campaignRequirements) {
    const step = ++currentStep;
    const { campaignRequirements } = campaignState;
    campaignData["Campaign Requirements"] = {
      Name: campaignRequirements.name,
      "Photo Examples":
        campaignRequirements.photoExamples?.thumbnailUrls ||
        campaignRequirements.photoExampleThumbnailUrls,
      Tags: campaignRequirements.tags,
      ...campaignRequirements.form,
      edit: () => updateStep(step),
    };
  }

  if (campaignState.campaignFlow?.campaignFlowId) {
    const step = ++currentStep;
    const campaignFlow =
      allData.campaignFlows[campaignState.campaignFlow.campaignFlowId];
    campaignData["Campaign Flow"] = {
      "Campaign Flow Name": campaignFlow.name,
      edit: () => updateStep(step),
    };
  } else if (campaignState.campaignFlow) {
    const step = ++currentStep;
    const { campaignFlow } = campaignState;
    campaignData["Campaign Flow"] = {
      "Campaign Flow Name": campaignFlow.name,
      edit: () => updateStep(step),
    };
  }

  if (campaignState.orderStep) {
    generateStepDetails({
      campaignState,
      allData,
      updateStep,
      campaignData,
      stepType: "orderStep",
      stepNumber: ++currentStep,
    });
  }

  if (campaignState.flipshopStep) {
    generateStepDetails({
      campaignState,
      allData,
      updateStep,
      campaignData,
      stepType: "flipshopStep",
      stepNumber: ++currentStep,
    });
  }

  if (campaignState.instagramStep) {
    generateStepDetails({
      campaignState,
      allData,
      updateStep,
      campaignData,
      stepType: "instagramStep",
      stepNumber: ++currentStep,
    });
  }

  if (campaignState.reviewStep) {
    generateStepDetails({
      campaignState,
      allData,
      updateStep,
      campaignData,
      stepType: "reviewStep",
      stepNumber: ++currentStep,
    });
  }

  return campaignData;
};

function SubmitStep({
  contractId,
  companyId,
  campaignState,
  allData,
  updateStep,
}) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const createCampaignSteps = useCreateCampaignSteps();
  const createCampaign = useCreateCampaign({ companyId, contractId });

  const onSubmit = async () => {
    try {
      setLoading(true);
      setError("");
      const campaignData = {
        contractId,
        ...campaignState.details,
        startDate: toCalendarDate(campaignState.details.startDate),
      };

      if (campaignState.product?.productId) {
        campaignData.productId = campaignState.product.productId;
      } else if (campaignState.product) {
        const { product } = campaignState;
        const productData = {
          name: product.name,
          categoryId: product.category[1],
          description: product.description,
          shortDescription: product.shortDescription,
          fullImageUrl: product.picture.fullImageUrl,
          thumbnailUrl: product.picture.thumbnailUrl,
          code: product.code,
          companyId,
          productPlatformId: product.productPlatformId,
          defaultPrice: product.productPrices.productPrice,
          relations: {
            productPrices: product.productPrices,
          },
        };
        campaignData.relations = {
          products: productData,
        };
      }

      if (campaignState.campaignRequirements?.campaignRequirementId) {
        campaignData.campaignRequirementId =
          campaignState.campaignRequirements.campaignRequirementId;
      } else if (campaignState.campaignRequirements) {
        const { campaignRequirements } = campaignState;
        const { photoExamples } = campaignRequirements;
        delete campaignRequirements.photoExamples;
        campaignData.relations = {
          ...campaignData.relations,
          campaignRequirements: {
            ...campaignRequirements,
            photoExampleFullUrls: photoExamples.fullImageUrls,
            photoExampleThumbnailUrls: photoExamples.thumbnailUrls,
          },
        };
      }

      if (campaignState.campaignFlow?.campaignFlowId) {
        campaignData.defaultFlowId = campaignState.campaignFlow.campaignFlowId;
      } else if (campaignState.campaignFlow) {
        const { campaignFlow } = campaignState;
        campaignData.relations = {
          ...campaignData.relations,
          campaignFlows: {
            name: campaignFlow.name,
          },
        };
      }

      const newlyCreatedEmailTemplates = [];

      if (campaignState.orderStep?.campaignStepId) {
        addCampaignStepToFlow(campaignData, "order", campaignState.orderStep);
      } else if (campaignState.orderStep) {
        const orderCampaignStep = await setupCampaignStep({
          stepName: "orderStep",
          stepType: "order",
          newlyCreatedEmailTemplates,
        });
        console.log(orderCampaignStep);
        addCampaignStepToFlow(campaignData, "order", orderCampaignStep);
      }

      if (campaignState.flipshopStep?.campaignStepId) {
        addCampaignStepToFlow(
          campaignData,
          "flipshop",
          campaignState.flipshopStep
        );
      } else if (campaignState.flipshopStep) {
        const flipshopCampaignStep = await setupCampaignStep({
          stepName: "flipshopStep",
          stepType: "flipshop",
          newlyCreatedEmailTemplates,
        });
        addCampaignStepToFlow(campaignData, "flipshop", flipshopCampaignStep);
      }

      if (campaignState.instagramStep?.campaignStepId) {
        addCampaignStepToFlow(
          campaignData,
          "instagram",
          campaignState.instagramStep
        );
      } else if (campaignState.instagramStep) {
        const instagramCampaignStep = await setupCampaignStep({
          stepName: "instagramStep",
          stepType: "instagram",
          newlyCreatedEmailTemplates,
        });
        addCampaignStepToFlow(campaignData, "instagram", instagramCampaignStep);
      }

      if (campaignState.reviewStep?.campaignStepId) {
        addCampaignStepToFlow(campaignData, "review", campaignState.reviewStep);
      } else if (campaignState.reviewStep) {
        const reviewCampaignStep = await setupCampaignStep({
          stepName: "reviewStep",
          stepType: "review",
          newlyCreatedEmailTemplates,
        });
        addCampaignStepToFlow(campaignData, "review", reviewCampaignStep);
      }

      await createCampaign.mutateAsync(campaignData);

      setLoading(false);

      history.push(`/contracts/${contractId}`);
    } catch (err) {
      setError(err.message || "An error occurred");
      setLoading(false);
    }
  };

  const setupCampaignStep = async ({
    stepName,
    stepType,
    newlyCreatedEmailTemplates,
  }) => {
    const step = campaignState[stepName];

    const { emailTemplateFlow } = step;

    const stepRequest = {
      name: step.name,
      stepType,
      maxReminders: step.maxReminders,
      hoursForReminder: step.hoursForReminder,
    };

    if (stepType === "instagram" && step.instaTags?.length > 0) {
      stepRequest.metadata = {
        regex: step.instaTags,
      };
    }

    for (let i = 0; i < emailTemplateFlow.length; i++) {
      const emailTemplate = newlyCreatedEmailTemplates.find(
        template => template.name === emailTemplateFlow[i].name
      );
      if (emailTemplate) {
        emailTemplateFlow[i].emailTemplateId = emailTemplate.emailTemplateId;
      }
    }

    const existingEmailTemplateIds = emailTemplateFlow
      .filter(template => !!template.emailTemplateId)
      .map(template => template.emailTemplateId);

    if (existingEmailTemplateIds.length > 0) {
      stepRequest.emailTemplateFlow = existingEmailTemplateIds;
    }

    const newEmailTemplates = emailTemplateFlow
      .filter(template => !template.emailTemplateId)
      .map(template => {
        const mappedTemplate = {
          ...template,
          onboard_variables: {
            "%ID%": "onboardId",
            "%DEADLINE_DATE%": "deadlineDate",
          },
          campaign_variables: {
            "%CAMPAIGN_LINK%": "link",
            "%CAMPAIGN_ID%": "campaignId",
            "%CONTRACT_ID%": "contractId",
          },
        };
        if (stepType === "review") {
          mappedTemplate.template = "d-6016e55887f94c6ba06c7fa1f182c3b4";
        }
        return mappedTemplate;
      });

    if (newEmailTemplates.length > 0) {
      stepRequest.relations = {
        emailTemplates: newEmailTemplates,
      };
    }

    const stepsCreationRequest = [stepRequest];

    const [campaignStep] = await createCampaignSteps.mutateAsync(
      stepsCreationRequest
    );

    if (campaignStep.relations?.emailTemplates) {
      newlyCreatedEmailTemplates.push(
        ...campaignStep.relations?.emailTemplates
      );
    }
    return campaignStep;
  };

  const addCampaignStepToFlow = (campaignData, stepName, campaignStep) => {
    const { stepType } = campaignState.campaignFlow.steps.find(
      step => step.name === stepName
    );

    if (stepType === "required") {
      if (!campaignData.relations.campaignFlows.requiredSteps) {
        campaignData.relations.campaignFlows.requiredSteps = [];
      }
      campaignData.relations.campaignFlows.requiredSteps.push(
        campaignStep.campaignStepId
      );
    } else if (stepType === "optional") {
      if (!campaignData.relations.campaignFlows.optionalSteps) {
        campaignData.relations.campaignFlows.optionalSteps = [];
      }
      campaignData.relations.campaignFlows.optionalSteps.push(
        campaignStep.campaignStepId
      );
    }
  };

  return (
    <div>
      {error && (
        <h4 className={styles.error}>
          <WarningOutlined fill="red" /> {error}
        </h4>
      )}
      <CampaignDetails
        data={generateCampaignDetails(campaignState, allData, updateStep)}
      />
      <div className={styles.createCampaignButtonContainer}>
        <Button loading={loading} type="primary" onClick={onSubmit}>
          Create Campaign
        </Button>
      </div>
    </div>
  );
}

SubmitStep.propTypes = {
  contractId: string,
  companyId: string,
  campaignState: object,
  allData: object,
  updateStep: func,
};

export default SubmitStep;
