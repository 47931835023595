import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Form,
  Space,
  Button,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Checkbox,
  Typography,
} from "antd";
import { any, func, object, string } from "prop-types";
import { LinkOutlined } from "@ant-design/icons";
import styles from "./AddNewCampaign.module.css";
import {
  defaultFormItemLayout,
  defaultButtonWrapperCol,
} from "../../../utils/formLayout";
import { selectFilter } from "../../../utils/filters";
import { useScrapeLink } from "../../../api/services";

function DetailsStep({
  form,
  onBack,
  productName = "",
  productCode = "",
  productPlatformPrefix = "",
}) {
  const [loading, setLoading] = useState(false);
  const [linkError, setLinkError] = useState("");
  const [ignoreLinkError, setIgnoreLinkError] = useState(false);
  const scrapeLink = useScrapeLink();

  useEffect(() => {
    if (productName) {
      form.setFieldsValue({ name: productName });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productName]);

  useEffect(() => {
    if (productName) {
      form.setFieldsValue({
        link: `${productPlatformPrefix || ""}${productCode}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCode, productPlatformPrefix]);

  const [userOptions, setUserOptions] = useState([]);

  const onNext = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      if (!ignoreLinkError) {
        setLinkError("");
        const formValues = form.getFieldsValue(true);
        if (formValues.link) {
          try {
            const linkResponse = await scrapeLink.mutateAsync({
              link: formValues.link,
            });
            if (linkResponse.includes(productName)) {
              form.submit();
            } else {
              setLinkError(`Link does not contain text "${productName}"`);
            }
          } catch (err) {
            setLinkError(err?.message);
          }
        }
      } else {
        form.submit();
      }
    } catch (err) {
      //ignore ant design validation error
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form
        className={styles.formContainer}
        name="detailsForm"
        {...defaultFormItemLayout}
        labelAlign="right"
        layout="horizontal"
        form={form}
        initialValues={{
          startDate: moment(),
          deadlineDays: 40,
        }}
      >
        <Space direction="vertical" className={styles.form} size="large">
          <Form.Item
            label="Campaign Name"
            name="name"
            rules={[{ required: true, message: "required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Target" name="target">
            <InputNumber />
          </Form.Item>

          <Form.Item label="Link" validateStatus={linkError ? "error" : ""}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Item
                noStyle
                name="link"
                rules={[
                  {
                    required: true,
                    message: "a valid link is required",
                    type: "url",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Button
                icon={<LinkOutlined />}
                onClick={() => {
                  const formValues = form.getFieldsValue(true);
                  window.open(formValues.link, "_blank");
                }}
              />
            </div>
            {linkError && (
              <div>
                <div>
                  <Typography.Text type="danger">{linkError}</Typography.Text>
                </div>
                <Checkbox
                  style={{ marginTop: "4px" }}
                  checked={ignoreLinkError}
                  onChange={e => setIgnoreLinkError(e.target.checked)}
                >
                  Ignore error message
                </Checkbox>
              </div>
            )}
          </Form.Item>

          <Form.Item
            label="Start Date"
            name="startDate"
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            label="Deadline Days"
            name="deadlineDays"
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            className={styles.buttonsContainer}
            wrapperCol={defaultButtonWrapperCol}
          >
            <Space direction="horizontal" size="large">
              {onBack && (
                <Button type="secondary" onClick={onBack}>
                  Previous
                </Button>
              )}
              <Button loading={loading} type="primary" onClick={onNext}>
                Next
              </Button>
            </Space>
          </Form.Item>
        </Space>
      </Form>
    </>
  );
}

DetailsStep.propTypes = {
  form: any,
  onBack: func,
  productName: string,
  productCode: string,
  productPlatformPrefix: string,
};

export default DetailsStep;
