import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import {
  createAuthenticatedRequest,
  defaultMutationOptions,
  defaultQueryOptions,
  executeRequest,
} from "./utils";

export const CONTRACT_DRAFTS_PATH = "contractDrafts";

export function useContractDrafts({
  includes,
  params,
  ...overrideOptions
} = {}) {
  const dispatch = useDispatch();

  return useQuery(
    [CONTRACT_DRAFTS_PATH, includes, params],
    () => fetchContractDrafts(dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

export async function fetchContractDrafts(dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    CONTRACT_DRAFTS_PATH,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}

export function useContractDraft() {
  // TODO
}

export function useUpdateContractDraft(contractDraftId) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    ({ body }) => updateContractDraft({ contractDraftId, body }, dispatch),
    {
      ...defaultMutationOptions,
      onSuccess: () => {
        queryClient.invalidateQueries(CONTRACT_DRAFTS_PATH);
      },
    }
  );
}

export async function updateContractDraft({ contractDraftId, body }, dispatch) {
  const requestObject = await createAuthenticatedRequest(
    `${CONTRACT_DRAFTS_PATH}/${contractDraftId}`,
    dispatch,
    {
      method: "PUT",
      body: JSON.stringify(body),
    }
  );

  return executeRequest(requestObject);
}

export function useCreateContractDraft() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    newContractDraft => createContractDraft(newContractDraft, dispatch),
    {
      ...defaultMutationOptions,
      onSuccess: () => {
        queryClient.invalidateQueries(CONTRACT_DRAFTS_PATH);
      },
    }
  );
}

export async function createContractDraft(contractDraft, dispatch) {
  const requestObject = await createAuthenticatedRequest(
    CONTRACT_DRAFTS_PATH,
    dispatch,
    {
      method: "POST",
      body: JSON.stringify(contractDraft),
    }
  );

  return executeRequest(requestObject);
}
