import React, { useState, useEffect } from "react";
import { object, string } from "prop-types";
import { EditOutlined } from "@ant-design/icons";
import {
  Form,
  Button,
  Modal,
  InputNumber,
  message,
  Input,
  Typography,
  Select,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Decimal } from "decimal.js";
import checkAuth from "../../../utils/checkAuth";
import {
  useCampaign,
  useUpdateCampaign,
  useValidRulesTables,
} from "../../../api/campaigns";
import { isLoading as setLoading } from "../../../redux/shared/sharedActions";
import RetoolButton from "../../shared/RetoolButton/RetoolButton";
import MoneyInput from "../../shared/MoneyInput";

export default function EditCampaignButton({ campaign, text }) {
  const [calculatedServiceFees, setCalculatedServiceFees] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const dispatch = useDispatch();
  const updateCampaign = useUpdateCampaign(campaign.campaignId);
  const { isLoading, isError, error, isSuccess } = updateCampaign;

  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);

  const product = campaign && campaign.relations.products;
  const productPrice =
    product &&
    product.relations.productPrices.find(p => p.price === product.defaultPrice);

  //redux
  const roles = useSelector(state => state.auth.roles);

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  useEffect(() => {
    if (campaign && product && productPrice) {
      form.setFieldsValue({
        productReimbursement: productPrice.productPrice,
        productServiceFees: productPrice.serviceFee,
        serviceFeeDescription: productPrice.serviceFeeDescription,
        target: campaign.target,
        state: campaign.state,
        rulesTable: campaign.rulesTable,
        sortOffset: campaign.sortOffset,
        featuredPriority: campaign.featuredPriority,
        featuredTag: campaign.featuredTag,
      });

      setTotalPrice(
        new Decimal(productPrice.productPrice).add(productPrice.serviceFee)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  useEffect(() => {
    if (isError) {
      message.error(error?.message || "An error occurred");
    }
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      setVisible(false);
      message.success("Campaign updated!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const { data: validRulesTables, isLoading: rulesTablesLoading } =
    useValidRulesTables();

  if (!campaign || !checkAuth.has(roles, ["admin"])) return null;

  const onSubmit = async () => {
    let values = null;
    try {
      values = await form.validateFields();
    } catch (err) {
      return;
    }

    const putBody = {
      target: values.target,
      state: values.state,
      rulesTable: values.rulesTable,
      sortOffset: values.sortOffset,
      featuredPriority: values.featuredPriority,
      featuredTag: values.featuredTag,
    };
    if (
      values.productReimbursement !== productPrice.productPrice ||
      values.productServiceFees !== productPrice.serviceFee ||
      values.serviceFeeDescription !== productPrice.serviceFeeDescription
    ) {
      //if value has been changed, update via relations
      putBody.relations = {
        products: {
          productId: campaign.productId,

          relations: {
            productPrices: {
              productPrice: values.productReimbursement,
              serviceFee: values.productServiceFees || 0.0,
              serviceFeeDescription: values.serviceFeeDescription,
            },
          },
        },
      };
    }
    updateCampaign.mutate({ campaignId: campaign.campaignId, body: putBody });
  };

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const onValuesChange = (changedValues, allValues) => {
    const {
      productReimbursement,
      percentageOfProduct,
      fixedFee = 0,
      productServiceFees,
    } = allValues;
    if (productReimbursement && percentageOfProduct) {
      const newProductServiceFee = new Decimal(productReimbursement)
        .mul(percentageOfProduct / 100)
        .add(fixedFee)
        .mul(100)
        .ceil()
        .div(100);

      setCalculatedServiceFees(newProductServiceFee.toFixed(2));
    }

    if (productReimbursement) {
      setTotalPrice(
        new Decimal(productReimbursement)
          .add(productServiceFees || 0)
          .toFixed(2)
      );
    }
  };

  return (
    <span>
      <Button
        type="primary"
        key="close"
        icon={<EditOutlined />}
        onClick={() => setVisible(true)}
      >
        {text}
      </Button>

      <Modal
        title={`Edit ${campaign.name} campaign`}
        visible={visible}
        onCancel={onCancel}
        footer={[
          <RetoolButton key="1" campaignId={`${campaign.campaignId}`} />,
          <Button key="2" disabled={isLoading} onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="3" type="primary" onClick={onSubmit} loading={isLoading}>
            Submit
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            productReimbursement: productPrice.productPrice,
            productServiceFees: productPrice.serviceFee,
            serviceFeeDescription: productPrice.serviceFeeDescription,
            percentageOfProduct: 10,
            target: campaign.target,
            state: campaign.state,
            rulesTable: campaign.rulesTable,
            sortOffset: campaign.sortOffset,
            featuredPriority: campaign.featuredPriority,
            featuredTag: campaign.featuredTag,
          }}
          onValuesChange={onValuesChange}
        >
          <Form.Item label="State" name="state">
            <Select
              options={[
                {
                  value: "active",
                },
                {
                  value: "closing",
                },
                {
                  value: "paused",
                },
                {
                  value: "done",
                },
                {
                  value: "disqualified",
                },
                {
                  value: "in_review",
                  label: "in review",
                },
              ]}
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              gap: "24px",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <Form.Item
              label="Rules Table"
              name="rulesTable"
              style={{ width: "calc(50% - 12px)" }}
            >
              <Select
                showSearch
                disabled={!validRulesTables || rulesTablesLoading}
                options={validRulesTables
                  ?.sort((a, b) => a.localeCompare(b))
                  .map(table => ({ value: table }))}
              />
            </Form.Item>
            <Form.Item
              label="Sort Offset"
              name="sortOffset"
              style={{ width: "calc(50% - 12px)" }}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Featured Tag"
              name="featuredTag"
              style={{ width: "calc(50% - 12px)" }}
            >
              <Select
                options={[
                  {
                    label: "Popular",
                    value: "popular",
                  },
                  {
                    label: "New Arrival",
                    value: "new_arrival",
                  },
                  {
                    label: "Ending Soon",
                    value: "ending_soon",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Featured Priority"
              name="featuredPriority"
              style={{ width: "calc(50% - 12px)" }}
            >
              <InputNumber />
            </Form.Item>
          </div>
          <Form.Item
            label="Product Reimbursement"
            name="productReimbursement"
            rules={[
              {
                required: true,
                message: "valid price is required",
              },
            ]}
          >
            <MoneyInput />
          </Form.Item>

          <Form.Item
            label="Service fee calculator"
            style={{ marginBottom: 0 }}
            extra="Use these fields to calculate the total service fee. Copy and paste into the Product Service Fees box below"
          >
            <div style={{ display: "flex", gap: "24px" }}>
              <Form.Item>
                $
                <Typography.Text copyable>
                  {calculatedServiceFees}
                </Typography.Text>
              </Form.Item>

              <Form.Item
                label="Percentage of Product"
                name="percentageOfProduct"
              >
                <InputNumber min={0} max={100} addonAfter="%" />
              </Form.Item>

              <Form.Item label="Fixed Fee" name="fixedFee">
                <MoneyInput />
              </Form.Item>
            </div>
          </Form.Item>

          <Form.Item label="Product Service Fees" name="productServiceFees">
            <MoneyInput />
          </Form.Item>

          <Form.Item
            label={`Total Price for ASIN "${campaign.code}"`}
            extra="IMPORTANT: All campaigns with same ASIN will be changed to this price"
          >
            <Typography.Text>${Number(totalPrice).toFixed(2)}</Typography.Text>
          </Form.Item>

          <Form.Item
            name="serviceFeeDescription"
            label="Service Fee Description"
          >
            <Input />
          </Form.Item>
          <Form.Item name="target" label="Target">
            <InputNumber min={0} allowClear />
          </Form.Item>
        </Form>
      </Modal>
    </span>
  );
}

EditCampaignButton.propTypes = {
  campaign: object,
  text: string,
};
