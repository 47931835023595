import React, { useEffect, useReducer, useState, cloneElement } from "react";
import clsx from "clsx";
import { Form, Steps } from "antd";
import { useParams } from "react-router-dom";
import styles from "./AddNewCampaign.module.css";
import GenericPageHeader from "../../shared/GenericPageHeader/GenericPageHeader";
import ProductStep from "./ProductStep";
import DetailsStep from "./DetailsStep";
import FlowStep from "./FlowStep";
import GenericStep from "./GenericStep";
import InstagramStep from "./InstagramStep";
import SubmitStep from "./SubmitStep";
import { useCompany } from "../../../api/companies";
import { useCampaignFlows } from "../../../api/campaignFlows";
import { useCampaignSteps } from "../../../api/campaignSteps";
import { useCategories } from "../../../api/categories";
import { useEmailTemplates } from "../../../api/emailTemplates";
import { useContractById } from "../../../api/contracts";
import RequirementsStep from "./RequirementsStep";
import { useCampaignRequirements } from "../../../api/campaignRequirements";
import { useProductPlatforms } from "../../../api/productPlatforms";

const { Step } = Steps;

const reducer = (state, newState) => ({
  ...state,
  ...newState,
});

const getStepData = (data, stepName) => {
  const campaignStep = JSON.parse(data.values.campaignStep);
  const step = {
    ...campaignStep,
  };
  if (!campaignStep.campaignStepId) {
    step.emailTemplateFlow = JSON.parse(campaignStep.emailTemplateFlow);
  }
  return { [stepName]: step };
};

//determines how to handle form data based on specific form
const dataHandler = {
  productForm: data => {
    return {
      product: JSON.parse(data.values.product),
    };
  },
  detailsForm: data => {
    return {
      details: { ...data.values },
    };
  },
  requirementsForm: data => {
    return {
      campaignRequirements: JSON.parse(data.values.campaignRequirements),
    };
  },
  flowForm: data => {
    return {
      campaignFlow: JSON.parse(data.values.campaignFlow),
    };
  },
  orderForm: data => getStepData(data, "orderStep"),
  flipshopForm: data => getStepData(data, "flipshopStep"),
  instagramForm: data => getStepData(data, "instagramStep"),
  reviewForm: data => getStepData(data, "reviewStep"),
};

function AddNewCampaign() {
  const { contractId, companyId } = useParams();

  const [productForm] = Form.useForm();
  const [detailsForm] = Form.useForm();
  const [flowForm] = Form.useForm();
  const [orderForm] = Form.useForm();
  const [flipshopForm] = Form.useForm();
  const [instagramForm] = Form.useForm();
  const [reviewForm] = Form.useForm();
  const [requirementsForm] = Form.useForm();

  const [currentStep, setCurrentStep] = useState(0);

  const [steps, setSteps] = useState([]);

  const [visibleFlowSteps, setVisibleFlowSteps] = useState({
    order: false,
    instagram: false,
    review: false,
    flipshop: false,
  });

  const [allData, setAllData] = useReducer(reducer, {});

  useProductPlatforms({
    onSuccess: productPlatforms => {
      setAllData({
        productPlatforms: productPlatforms.reduce((acc, platform) => {
          return { ...acc, [platform.productPlatformId]: platform };
        }, {}),
      });
    },
  });

  const { data: contract } = useContractById({
    contractId,
    includes: ["campaigns", "products", "companies"],
    params: { stats: true },
  });

  const { data: company, isLoading: isLoadingCompany } = useCompany({
    companyId,
    includes: ["products"],
    onSuccess: _company => {
      setAllData({
        products: _company.relations.products.reduce((acc, product) => {
          return { ...acc, [product.productId]: product };
        }, {}),
      });
    },
  });

  const { isLoading: isLoadingCampaignFlows } = useCampaignFlows({
    includes: ["campaignSteps"],
    onSuccess: campaignFlows => {
      setAllData({
        campaignFlows: campaignFlows.reduce((acc, flow) => {
          return { ...acc, [flow.campaignFlowId]: flow };
        }, {}),
      });
    },
  });

  const { isLoading: isLoadingCampaignSteps } = useCampaignSteps({
    params: {
      sortColumn: "name",
      sortDirection: "asc",
    },
    onSuccess: campaignSteps => {
      setAllData({
        campaignSteps: campaignSteps.reduce((acc, step) => {
          return { ...acc, [step.campaignStepId]: step };
        }, {}),
      });
    },
  });

  const { isLoading: isLoadingCampaignRequirements } = useCampaignRequirements({
    params: {
      sortColumn: "name",
      sortDirection: "asc",
    },
    onSuccess: campaignRequirements => {
      setAllData({
        campaignRequirements: campaignRequirements.reduce(
          (acc, requirement) => {
            return { ...acc, [requirement.campaignRequirementId]: requirement };
          },
          {}
        ),
      });
    },
  });

  useCategories({
    params: {
      sortColumn: "categories.name",
      sortDirection: "asc",
    },
    onSuccess: categories => {
      setAllData({
        categories: categories.reduce((acc1, category) => {
          const childrenCategories = category?.children || [];
          return {
            ...acc1,
            [category.categoryId]: {
              ...category,
              children: childrenCategories.reduce((acc2, subcategory) => {
                return { ...acc2, [subcategory.categoryId]: subcategory };
              }, {}),
            },
          };
        }, {}),
      });
    },
  });

  useEmailTemplates({
    onSuccess: emailTemplates => {
      setAllData({
        emailTemplates: emailTemplates.reduce((acc, template) => {
          return { ...acc, [template.emailTemplateId]: template };
        }, {}),
        requiredEmailTemplates: emailTemplates.reduce((acc, template) => {
          if (
            template.template ===
            process.env.REACT_APP_SENDGRID_REQUIRED_REMINDER
          ) {
            return { ...acc, [template.emailTemplateId]: template };
          }
          return acc;
        }, {}),
        optionalEmailTemplates: emailTemplates.reduce((acc, template) => {
          if (
            template.template ===
            process.env.REACT_APP_SENDGRID_OPTIONAL_REMINDER
          ) {
            return { ...acc, [template.emailTemplateId]: template };
          }
          return acc;
        }, {}),
      });
    },
  });

  const addEmailTemplate = (emailTemplate, stepType) => {
    const emailTemplatesData = {
      emailTemplates: {
        [emailTemplate.name]: emailTemplate,
        ...allData.emailTemplates,
      },
    };
    if (stepType === "order" || stepType === "instagram") {
      emailTemplatesData.requiredEmailTemplates = {
        [emailTemplate.name]: emailTemplate,
        ...allData.requiredEmailTemplates,
      };
    } else if (stepType === "review") {
      emailTemplatesData.optionalEmailTemplates = {
        [emailTemplate.name]: emailTemplate,
        ...allData.optionalEmailTemplates,
      };
    }
    setAllData(emailTemplatesData);
  };

  const [campaignState, setCampaignState] = useReducer(reducer, {});

  const [error, setError] = useState("");

  const [navigationDisabled, setNavigationDisabled] = useReducer(reducer, {});

  const onBack = () => {
    updateStep(currentStep - 1);
  };

  const onFormDisabled = disabled => {
    //if the current form is disabled (due to the second form of the TwoComponentSwitch showing), disallow step navigation
    setNavigationDisabled({ [currentStep]: disabled });
    if (!disabled) {
      setError("");
    }
  };

  const updateStep = async step => {
    if (step < currentStep) {
      setCurrentStep(step);
      setError("");
      return;
    }
    // when updating to a particular step ahead of the current step, iterate over the forms leading up to that step to check if they are valid
    // if any one isn't valid, it will stop at that particular step prompting the user to fill out the required missing/invalid fields
    for (let i = currentStep; i < step; i++) {
      const { form } = steps[i];
      if (navigationDisabled[i]) {
        //this will happen if the second component of the TwoComponentSwitch is visible and the user tries to move forward using the steps
        setError("You must submit this form to continue.");
        return;
      }
      if (form) {
        try {
          await form.validateFields();
          const formValues = form.getFieldsValue(true);
          if (Object.keys(formValues).length === 0) {
            setError("You must submit this form to continue.");
            return;
          }
          form.submit();
        } catch (error) {
          return;
        }
      } else {
        setError("");
        goToNextStep();
      }
    }
  };

  const stepsContent = [
    {
      visible: true,
      component: (
        <ProductStep
          form={productForm}
          onFormDisabled={onFormDisabled}
          companyId={companyId}
          products={allData.products}
          loading={isLoadingCompany}
          categories={allData.categories}
          productPlatforms={allData.productPlatforms}
        />
      ),
    },
    {
      visible: true,
      component: (
        <DetailsStep
          form={detailsForm}
          onBack={onBack}
          productName={campaignState?.product?.name}
          productCode={campaignState?.product?.code}
          productPlatformPrefix={
            allData.productPlatforms &&
            allData.productPlatforms[campaignState?.product?.productPlatformId]
              ? allData?.productPlatforms[
                  campaignState?.product?.productPlatformId
                ].linkPrefix
              : ""
          }
        />
      ),
    },
    {
      visible: true,
      component: (
        <RequirementsStep
          form={requirementsForm}
          onFormDisabled={onFormDisabled}
          companyId={companyId}
          requirements={allData.campaignRequirements}
          loading={isLoadingCampaignRequirements}
          contractName={contract?.name}
        />
      ),
    },
    {
      visible: true,
      component: (
        <FlowStep
          form={flowForm}
          campaignFlows={allData.campaignFlows}
          onBack={onBack}
          onFormDisabled={onFormDisabled}
          contractName={contract?.name}
          loading={isLoadingCampaignFlows}
        />
      ),
    },
    {
      visible: visibleFlowSteps.order,
      component: (
        <GenericStep
          stepType="order"
          form={orderForm}
          onBack={onBack}
          onFormDisabled={onFormDisabled}
          contractName={contract?.name}
          campaignSteps={allData.campaignSteps}
          loading={isLoadingCampaignSteps}
          companyName={company?.name}
          platform={campaignState.details?.platform}
          link={campaignState.details?.link}
          emailTemplates={allData.requiredEmailTemplates}
          addEmailTemplate={addEmailTemplate}
        />
      ),
    },
    {
      visible: visibleFlowSteps.flipshop,
      component: (
        <GenericStep
          stepType="flipshop"
          form={flipshopForm}
          onBack={onBack}
          onFormDisabled={onFormDisabled}
          contractName={contract?.name}
          campaignSteps={allData.campaignSteps}
          loading={isLoadingCampaignSteps}
          companyName={company?.name}
          platform={campaignState.details?.platform}
          link={campaignState.details?.link}
          emailTemplates={allData.requiredEmailTemplates}
          addEmailTemplate={addEmailTemplate}
        />
      ),
    },
    {
      visible: visibleFlowSteps.instagram,
      component: (
        <InstagramStep
          form={instagramForm}
          onBack={onBack}
          onFormDisabled={onFormDisabled}
          contractName={contract?.name}
          campaignSteps={allData.campaignSteps}
          loading={isLoadingCampaignSteps}
          companyName={company?.name}
          platform={campaignState.details?.platform}
          link={campaignState.details?.link}
          emailTemplates={allData.requiredEmailTemplates}
          addEmailTemplate={addEmailTemplate}
        />
      ),
    },
    {
      visible: visibleFlowSteps.review,
      component: (
        <GenericStep
          stepType="review"
          form={reviewForm}
          onBack={onBack}
          onFormDisabled={onFormDisabled}
          contractName={contract?.name}
          campaignSteps={allData.campaignSteps}
          loading={isLoadingCampaignSteps}
          companyName={company?.name}
          platform={campaignState.details?.platform}
          link={campaignState.details?.link}
          emailTemplates={allData.optionalEmailTemplates}
          addEmailTemplate={addEmailTemplate}
        />
      ),
    },
    {
      visible: true,
      component: (
        <SubmitStep
          campaignState={campaignState}
          allData={allData}
          contractId={contractId}
          companyId={companyId}
          updateStep={updateStep}
        />
      ),
    },
  ];

  const getStepStatus = step => {
    if (currentStep === step) {
      return "process";
    }
    if (currentStep > step) {
      return "finish";
    }
    return "wait";
  };

  useEffect(() => {
    const newSteps = [
      {
        title: "Product",
        form: productForm,
        status: "process",
        visible: true,
      },
      {
        title: "Details",
        form: detailsForm,
        status: "wait",
        visible: true,
      },
      {
        title: "Requirements",
        form: requirementsForm,
        status: "wait",
        visible: true,
      },
      {
        title: "Flow",
        status: "wait",
        form: flowForm,
        visible: true,
      },
      {
        title: "Order Step",
        status: "wait",
        form: orderForm,
        visible: visibleFlowSteps.order,
      },
      {
        title: "Flipshop Step",
        status: "wait",
        form: flipshopForm,
        visible: visibleFlowSteps.flipshop,
      },
      {
        title: "Instagram Step",
        status: "wait",
        form: instagramForm,
        visible: visibleFlowSteps.instagram,
      },
      {
        title: "Review Step",
        status: "wait",
        form: reviewForm,
        visible: visibleFlowSteps.review,
      },
      {
        title: "Submit",
        status: "wait",
        visible: true,
      },
    ];

    newSteps
      .filter(step => step.visible)
      .forEach((step, i) => {
        step.status = getStepStatus(i);
      });

    setSteps(newSteps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleFlowSteps, currentStep]);

  const goToNextStep = () => {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
  };

  const goToNextFlowStep = flowData => {
    //new flow
    const newVisibleFlowSteps = {
      order: false,
      instagram: false,
      review: false,
      flipshop: false,
    };
    if (!flowData.campaignFlowId) {
      const flowSteps = [];
      flowSteps.push(...flowData.steps.map(f => f.name));

      for (const flowStep of flowSteps) {
        newVisibleFlowSteps[flowStep] = true;
      }
    }
    const newCampaignState = { ...campaignState };
    const flowStepKeys = Object.keys(newVisibleFlowSteps);
    for (const flowStepKey of flowStepKeys) {
      if (!newVisibleFlowSteps[flowStepKey]) {
        newCampaignState[`${flowStepKey}Step`] = undefined;
      }
    }
    setCampaignState(newCampaignState);
    setVisibleFlowSteps(newVisibleFlowSteps);
    goToNextStep();
  };

  const onNext = async (formName, data) => {
    //this will automatically be triggered each time any form within the overall campaign creation UI is submitted
    const nextStep = currentStep + 1;
    if (nextStep < steps.length) {
      const formData = { ...dataHandler[formName](data) };
      if (formName === "flowForm") {
        goToNextFlowStep(formData.campaignFlow);
      } else {
        goToNextStep();
      }
      setCampaignState(formData);
    }
  };

  return (
    <>
      <GenericPageHeader
        title={
          campaignState.details?.name
            ? `New Campaign - ${campaignState.details?.name}`
            : "Create New Campaign"
        }
        showBreadcrumb={false}
      />
      <div className={styles.container}>
        <Steps
          onChange={updateStep}
          current={currentStep}
          className={styles.steps}
          labelPlacement="vertical"
        >
          {steps
            .filter(step => step.visible)
            .map(step => (
              <Step status={step.status} key={step.title} title={step.title} />
            ))}
        </Steps>

        <div className={styles.error}>{error}</div>

        <Form.Provider onFormFinish={onNext}>
          {stepsContent
            .filter(step => step.visible)
            .map((step, i) => (
              <div
                key={i}
                className={clsx({
                  [styles.visible]: currentStep === i,
                  [styles.hidden]: currentStep !== i,
                })}
              >
                {cloneElement(step.component, { visible: currentStep === i })}
              </div>
            ))}
        </Form.Provider>
      </div>
    </>
  );
}

export default AddNewCampaign;
